import React from 'react'
import { Link as GLink } from 'gatsby'
import cn from 'classnames'

const Link = ({ className = '', children, to, external = false, ...rest }) =>
  external ? (
    <a
      className={cn('text-blue-500 underline hover:text-blue-700', className)}
      href={to}
      target="_blank"
      {...rest}
    >
      {children}
    </a>
  ) : (
    <GLink
      className={cn('text-blue-500 underline hover:text-blue-700', className)}
      activeClassName="text-red-500 hover:text-red-700"
      to={to}
      {...rest}
    >
      {children}
    </GLink>
  )

export default Link
