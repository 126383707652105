import React from 'react'

import LandingLayout from '@layouts/Landing'
import { Container, H1, H2 as H2Default } from '@layouts/Landing/components'
import { styled } from '@util/tw'
import Link from '@components/Link'
import SEO from '@components/seo'

const H2 = styled(H2Default, 'mt-8')

const P = styled('p', 'mb-2')

export default () => {
  return (
    <LandingLayout>
      <SEO title="Privacy Policy" description="Sentimentor Privacy Policy" />

      <Container className="flex-col">
        <H1 style={{ color: 'black' }}>Privacy Policy</H1>
        <p>Last revised on 2020-07-01</p>

        <H2>The Gist</H2>

        <P>
          Mad Filaments will collect certain non-personally identify information
          about you as you use our sites. We may use this data to better
          understand our users. We can also publish this data, but the data will
          be about a large group of users, not individuals.
        </P>

        <P>
          We will also ask you to provide personal information, but you'll
          always be able to opt out. If you give us personal information, we
          won't do anything evil with it.
        </P>

        <P>We can also use cookies, but you can choose not to store these.</P>

        <P>
          That's the basic idea, but you must read through the entire Privacy
          Policy below and agree with all the details before you use any of our
          sites.
        </P>

        <H2>Reuse</H2>

        <P>
          This document is based upon the{' '}
          <Link to="http://automattic.com/privacy/" external>
            Automattic Privacy Policy
          </Link>{' '}
          and is licensed under{' '}
          <Link external to="http://creativecommons.org/licenses/by-sa/2.5/">
            Creative Commons Attribution Share-Alike License 2.5
          </Link>
          . Basically, this means you can use it verbatim or edited, but you
          must release new versions under the same license and you have to
          credit Automattic somewhere (like this!). Automattic is not connected
          with and does not sponsor or endorse Mad Filaments or its use of the
          work.
        </P>

        <P>
          Mad Filaments, Inc. ("Mad Filaments") makes available services include
          our web sites (
          <Link to="https://sentimentor.co" external>
            https://sentimentor.co
          </Link>
          ), our blog, our API, and any other software, sites, and services
          offered by Mad Filaments in connection to any of those (taken
          together, the "Service"). It is Mad Filaments' policy to respect your
          privacy regarding any information we may collect while operating our
          websites.
        </P>

        <H2>Questions</H2>

        <P>
          If you have question about this Privacy Policy, please contact us at{' '}
          <Link to="mailto:contact@sentimentor.co" external>
            contact@sentimentor.co
          </Link>
        </P>

        <H2>Visitors</H2>

        <P>
          Like most website operators, Mad Filaments collects
          non-personally-identifying information of the sort that web browsers
          and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each
          visitor request. Mad Filaments' purpose in collecting non-personally
          identifying information is to better understand how Mad Filaments'
          visitors use its website. From time to time, Mad Filaments may release
          non-personally-identifying information in the aggregate, e.g., by
          publishing a report on trends in the usage of its website.
        </P>

        <P>
          Mad Filaments also collects potentially personally-identifying
          information like Internet Protocol (IP) addresses. Mad Filaments does
          not use such information to identify its visitors, however, and does
          not disclose such information, other than under the same circumstances
          that it uses and discloses personally-identifying information, as
          described below. We may also collect and use IP addresses to block
          users who violated our Terms of Service.
        </P>

        <H2>Gathering of Personally-Identifying Information</H2>

        <P>
          Certain visitors to Mad Filaments' websites choose to interact with
          Mad Filaments in ways that require Mad Filaments to gather
          personally-identifying information. The amount and type of information
          that Mad Filaments gathers depends on the nature of the interaction.
          Mad Filaments collects such information only insofar as is necessary
          or appropriate to fulfill the purpose of the visitor's interaction
          with Mad Filaments. Mad Filaments does not disclose
          personally-identifying information other than as described below. And
          visitors can always refuse to supply personally-identifying
          information, with the caveat that it may prevent them from engaging in
          certain Service-related activities.
        </P>

        <P>
          Additionally, some interactions, such as posting a comment, may ask
          for optional personal information. For instance, when posting a
          comment, may provide a website that will be displayed along with a
          user's name when the comment is displayed. Supplying such personal
          information is completely optional and is only displayed for the
          benefit and the convenience of the user.
        </P>

        <H2>Aggregated Statistics</H2>

        <P>
          Mad Filaments may collect statistics about the behavior of visitors to
          the Service. For instance, Mad Filaments may monitor the most popular
          parts of the Service. Mad Filaments may display this information
          publicly or provide it to others. However, Mad Filaments does not
          disclose personally-identifying information other than as described
          below.
        </P>

        <H2>Protection of Certain Personally-Identifying Information</H2>

        <P>
          Mad Filaments discloses potentially personally-identifying and
          personally-identifying information only to those of its employees,
          contractors and affiliated organizations that (i) need to know that
          information in order to process it on Mad Filaments' behalf or to
          provide services available at Mad Filaments' websites, and (ii) that
          have agreed not to disclose it to others. Some of those employees,
          contractors and affiliated organizations may be located outside of
          your home country; by using the Service, you consent to the transfer
          of such information to them. Mad Filaments will not rent or sell
          potentially personally-identifying and personally-identifying
          information to anyone. Other than to its employees, contractors and
          affiliated organizations, as described above, Mad Filaments discloses
          potentially personally-identifying and personally-identifying
          information only when required to do so by law, or when Mad Filaments
          believes in good faith that disclosure is reasonably necessary to
          protect the property or rights of Mad Filaments, third parties or the
          public at large. If you are a registered user of the Service and have
          supplied your email address, Mad Filaments may occasionally send you
          an email to tell you about new features, solicit your feedback, or
          just keep you up to date with what's going on with Mad Filaments and
          our products. We primarily use our website and blog to communicate
          this type of information, so we expect to keep this type of email to a
          minimum. If you send us a request (for example via a support email or
          via one of our feedback mechanisms), we reserve the right to publish
          it in order to help us clarify or respond to your request or to help
          us support other users. Mad Filaments takes all measures reasonably
          necessary to protect against the unauthorized access, use, alteration
          or destruction of potentially personally-identifying and
          personally-identifying information.
        </P>

        <H2>Cookies</H2>
        <P>
          A cookie is a string of information that a website stores on a
          visitor's computer, and that the visitor's browser provides to the
          Service each time the visitor returns. Mad Filaments uses cookies to
          help Mad Filaments identify and track visitors, their usage of Mad
          Filaments Service, and their Service access preferences. Mad Filaments
          visitors who do not wish to have cookies placed on their computers
          should set their browsers to refuse cookies before using Mad
          Filaments' websites, with the drawback that certain features of Mad
          Filaments' websites may not function properly without the aid of
          cookies.
        </P>

        <H2>Data Storage</H2>
        <P>
          Mad Filaments uses third party vendors and hosting partners to provide
          the necessary hardware, software, networking, storage, and related
          technology required to run the Service. You understand that although
          you retain full rights to your data, it may be stored on third party
          storage and transmitted through third party networks.
        </P>

        <H2>Privacy Policy Changes</H2>
        <P>
          Although most changes are likely to be minor, Mad Filaments may change
          its Privacy Policy from time to time, and in Mad Filaments' sole
          discretion. Mad Filaments encourages visitors to frequently check this
          page for any changes to its Privacy Policy. Your continued use of this
          site after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </P>
      </Container>
    </LandingLayout>
  )
}
