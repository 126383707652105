import React from 'react'
import { Link } from 'gatsby'

import Hotjar from '@components/Hotjar'

const FooterLink = ({ children, to }) => (
  <li>
    <Link to={to} className="text-sm hover:underline">
      {children}
    </Link>
  </li>
)

const FooterSection = ({ title, links }) => (
  <div className="w-full md:w-auto mt-2">
    <div className="font-bold text-gray-600 mb-2">{title}</div>
    <ul>
      {links.map(link =>
        link.to ? (
          <FooterLink to={link.to} children={link.children} key={link.to} />
        ) : (
          <li key={link.children}>
            <p className="text-sm text-gray-500">
              {link.children} (Coming Soon)
            </p>
          </li>
        ),
      )}
    </ul>
  </div>
)

const Footer = () => {
  return (
    <footer className="bg-gray-300 p-10 md:p-20">
      <div className="container flex flex-wrap mx-auto justify-around">
        <FooterSection
          title="Product"
          links={[
            { to: '/pricing', children: 'Pricing' },
            // { to: '/features', children: 'Features' },
            // { to: '/use-cases/support', children: 'For Customer Support' },
            // { to: '/use-cases/pms', children: 'For Product Managers' },
            // { to: '/use-cases/success', children: 'For Customer Success' },
          ]}
        />
        <FooterSection
          title="Integrations"
          links={[
            { to: '/integrations/intercom', children: 'Intercom' },
            { children: 'Zoho SalesIQ' },
            { children: 'Drift' },
            { children: 'CSV' },
            // { to: '/integrations/zoho', children: 'Zoho SalesIQ' },
            // { to: '/integrations/drift', children: 'Drift' },
            // { to: '/integrations/csv', children: 'CSV' },
          ]}
        />
        <FooterSection
          title="Legal"
          links={[
            { to: '/legal/terms', children: 'Terms of Service' },
            { to: '/legal/privacy', children: 'Privacy Policy' },
            // { to: '/legal/cookies', children: 'Cookie Policy' },
            // { to: '/legal/gdpr', children: 'GDPR Compliance' },
          ]}
        />
      </div>
      <div className="text-xs text-gray-600 text-center mt-8">
        © 2020 Sentimentor. Mad Filaments FM0783826. BC, Canada.
      </div>

      <Hotjar />
    </footer>
  )
}

export default Footer
