import React, { useState } from 'react'
import cn from 'classnames'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'gatsby'

import { ReactComponent as Logo } from '@assets/Sentimentor.svg'
import tw from '@util/tw'

const NavLink = tw(
  Link,
  'block mt-4 md:inline-block md:mt-0 text-blue-100 hover:text-white md:mr-4',
)

const ButtonLink = tw(
  Link,
  'inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-400 hover:bg-white mt-4 md:mt-0 md:ml-8',
)

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <header className="flex items-center justify-between flex-wrap bg-blue-400 p-6 px-6 lg:px-20">
      <div className="flex-shrink-0 text-white">
        <Link to="/">
          <Logo
            className="h-12 w-auto text-white fill-current cursor-pointer"
            css={{
              stroke: 'black',
              strokeWidth: 5,
              strokeDasharray: '1500 1500',
              strokeDashoffset: 1500,
              transition: 'all 500ms',
              ':hover': {
                strokeDashoffset: 0,
              },
            }}
          />
        </Link>
      </div>
      <div className="block md:hidden">
        <button
          onClick={() => setOpen(!open)}
          className="flex items-center px-3 py-2 border rounded text-blue-200 border-blue-200 hover:text-white hover:border-white"
        >
          {!open && <FaBars className="h-3 w-3" />}
          {open && <FaTimes className="h-3 w-3" />}
        </button>
      </div>
      <nav
        className={cn('w-full block md:flex md:items-center md:w-auto', {
          hidden: !open,
        })}
      >
        <div className="text-sm text-center md:text-left md:flex-grow">
          {/* <NavLink to="/features">Features</NavLink> */}
          {/* <NavLink to="/integrations">Integrations</NavLink> */}
          {/* <NavLink to="/use-cases">Use Cases</NavLink> */}
          <NavLink to="/pricing">Pricing</NavLink>
        </div>
        <div className="flex justify-center">
          <ButtonLink to="/app/signup">Get Started</ButtonLink>
        </div>
      </nav>
    </header>
  )
}

export default Header
