import tw, { styled } from '@util/tw'

export const Container = styled('div', 'container flex mx-auto p-10 md:p-20')

export const H1 = styled(
  'h1',
  'text-white font-light text-3xl sm:text-5xl leading-none mb-4',
)

export const H2 = styled(
  'h2',
  'font-light text-2xl sm:text-4xl leading-none mb-4',
)

export const H3 = styled(
  'h3',
  'font-light text-xl sm:text-3xl leading-none mb-4',
)

export const H4 = styled(
  'h4',
  'font-light text-l sm:text-2xl leading-none mb-4',
)
